import React, { ReactElement } from "react"

import Automation from "../../assets/automation.svg"
import Efficiency from "../../assets/efficiency.svg"
import Focus from "../../assets/focus.svg"
import Result from "../../assets/result.svg"

const features = [
  {
    title: "Automation",
    icon: Automation,
    description:
      "Leverage tech to remove the barriers to the best analysis and insights.",
  },
  {
    title: "Efficiency",
    icon: Efficiency,
    description:
      "Cut the stress and maximise profitability with best practice processes built in.",
  },
  {
    title: "Focus",
    icon: Focus,
    description: "Focus on strategy advocacy and client experience.",
  },
  {
    title: "Results",
    icon: Result,
    description: "Get the best results for your clients.",
  },
]

export default function LawliteFeature(): ReactElement {
  return (
    <div className="bg-br-primary-blue flex items-center flex-col text-center pt-10  pb-24">
      <h2 className="text-3xl md:text-4xl  text-white font-bold px-4">
        Next generation tools for next level results
      </h2>
      <div className="flex flex-col items-center lg:items-start lg:justify-evenly w-full lg:flex-row lg:flex-wrap lg:mt-20">
        {features.map(({ title, icon: Icon, description }) => (
          <div
            key={title}
            className=" lg:mt-0 mt-10  flex flex-col items-center max-w-[200px] text-white svg-fill-override"
          >
            <Icon className="w-20 text-white" />
            <div className="font-black text-xl mt-4">{title}</div>
            <div className="text-lg mt-4 font-medium">{description}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
